import * as React from "react";
import Layout from "../components/Layout";

const NotFoundPage = () => (
  <Layout>
    <div className="container section">
      <h1 style={{paddingTop: "128px", color: "black"}}>Termos de uso</h1>
      <p style={{paddingBottom: "64px"}}>Aqui os termos de uso.</p>
    </div>
  </Layout>
);

export default NotFoundPage;
